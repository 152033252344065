<template>
  <section class="error--page py-5">
    <div class="error-container">
      <div class="mb-5">
        <div class="title--text error--code">404</div>
        <div class="subtitle">
          {{ $t('errorPage.pageNotFound') }}
        </div>
      </div>
      <img
        width="300"
        src="https://s3-ap-southeast-1.amazonaws.com/rentfix/static/img/icon/error.svg"
      />
    </div>
  </section>
</template>

<script>
export default {
  layout: 'focus',
};
</script>

<style></style>
